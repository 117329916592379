.container {
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 50px;
  width: 240px;
  height: 60px;
  border: none;
  border-radius: 8px;
  background-color: #e93237;
  font: normal normal normal 20px/34px Gotham;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 20px;
}
