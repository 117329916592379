.Container {
  background-color: #edf2f4;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo {
  align-self: flex-end;
  width: 250px;
  padding-top: 80px;
  padding-right: 100px;
}

.title {
  width: 700px;
  margin-top: -20px;
  animation-name: title;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes title {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.925;
  }
  20% {
    opacity: 0.85;
  }
  30% {
    opacity: 0.775;
  }
  40% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.625;
  }
  60% {
    opacity: 0.55;
  }
  70% {
    opacity: 0.475;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.325;
  }
  100% {
    opacity: 0.25;
  }
}

.content {
  text-align: center;
  font: normal normal normal 24px/34px Gotham;
  color: #34373f;
  width: 55%;
}

.footer {
  background-color: #e93237;
  width: 100vw;
  height: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerText {
  font: normal normal bold 36px/40px GothamBold;
  color: white;
}
@media screen and (max-height: 820px) {
  .title {
    width: 550px;
  }
}
