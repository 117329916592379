.container {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  animation-name: surveyYear;
  animation-duration: 0.1s;
}

@keyframes surveyYear {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(90%);
  }
  20% {
    transform: translateX(80%);
  }
  30% {
    transform: translateX(70%);
  }
  40% {
    transform: translateX(60%);
  }
  50% {
    transform: translateX(50%);
  }
  60% {
    transform: translateX(40%);
  }
  70% {
    transform: translateX(30%);
  }
  80% {
    transform: translateX(20%);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }
}

.questionContainer {
  height: 500px;
  width: 88%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-end;
}

.question {
  text-align: left;
  font: normal normal normal 32px/44px Gotham;
  letter-spacing: 0px;
  color: #e93137;
  opacity: 1;
}

.questionContainerSmall {
  display: flex;
}

.answerList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.label {
  text-align: left;
  font: normal normal normal 32px/65px Gotham;
  color: #e93137;
  cursor: pointer;
  position: relative;
  height: 60px;
}

.correctAnswer {
  font-weight: bolder;
}

.closeIcon {
  width: 35px;
  position: absolute;
  left: -40px;
  top: 15px;
  filter: saturate(620%);
}
.checkIcon {
  width: 35px;
  position: absolute;
  left: -40px;
  top: 15px;
  filter: saturate(220%);
}

.radioButton {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
}

.media {
  font-style: normal;
}

.medioCorrect {
  font-style: normal;
  font-weight: bolder;
}

.mediaNotCorrect {
  font-weight: normal;
}

.trademark {
  text-align: left;
  font: normal normal normal 11px/15px Gotham;
  letter-spacing: 0px;
  color: #959595;
  opacity: 1;
  width: 70%;
  position: absolute;
  bottom: 4%;
}

.button {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 160px;
  height: 60px;
  border: none;
  border-radius: 8px;
  background-color: #e93237;
  font: normal normal normal 20px/34px Gotham;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonText {
  margin: 0;
  padding: 0;
  width: 90%;
}

.button:disabled,
.button[disabled] {
  opacity: 50%;
}

@media screen and (max-height: 820px) {
  .questionContainer {
    justify-content: flex-start;
  }

  .question {
    font: normal normal normal 28px/44px Gotham;

  }
  .label {
    text-align: left;
    font: normal normal normal 28px/65px Gotham;
    color: #e93137;
    cursor: pointer;
    position: relative;
    height: 60px;
  }
}
