.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.leftHeader {
  display: flex;
  gap: 30px;
}

.title {
  width: 300px;
  padding-left: 80px;
}

.redDot {
  width: 50px;
  height: 50px;
  background-color: #e93137;
  border-radius: 50%;
  align-self: center;
}

.logo {
  width: 250px;
  padding-right: 80px;
}

@media screen and (max-height: 820px) {
  .logo {
    width: 200px;
  }
  .redDot {
    width: 30px;
    height: 30px;
  }
  .title {
    width: 250px;
  }
}
