@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');

@font-face {
  font-family: Gotham;
  src: url('/src/assets/fonts/GothamBook.ttf');
}
@font-face {
  font-family: GothamBold;
  src: url('/src/assets/fonts/GothamBold.ttf');
}

* {
  font-family: 'Roboto', 'Roboto Condensed', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}