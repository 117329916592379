.container {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  position: relative;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font: 98px/138px GothamBold;
  font-weight: 200;
  letter-spacing: 0px;
  color: #e93137;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 100px;
}

.title2 {
  text-align: center;
  font: normal normal normal 54px/76px Gotham;
  color: #e93137;
  opacity: 1;
  margin: 0;
  vertical-align: text-bottom;
  margin-top: 6px;
  padding-top: 5px;
}

.copyright {
  text-align: center;
  font: normal normal medium 18px/25px Gotham;
  letter-spacing: 0px;
  color: #959595;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
}

.footer {
  background-color: #e93237;
  width: 100vw;
  height: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footerText {
  font: normal normal bold 36px/50px GothamBold;
  color: #ffffff;
  margin: 0;
}

@media screen and (max-height: 860px) {
  .title {
    font: 80px/138px GothamBold;
  }
  .title2 {
    font: normal normal normal 40px/76px Gotham;
  }
  .footer {
    height: 90px;
  }
  .copyright {
    margin-top: 100px;
  }
}

@media screen and (max-height: 720px) {
  .copyright {
    margin-top: 50px;
  }
}
