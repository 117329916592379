.container {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  position: relative;
  animation-name: surveyYear;
  animation-duration: 0.1s;
}

@keyframes surveyYear {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(90%);
  }
  20% {
    transform: translateX(80%);
  }
  30% {
    transform: translateX(70%);
  }
  40% {
    transform: translateX(60%);
  }
  50% {
    transform: translateX(50%);
  }
  60% {
    transform: translateX(40%);
  }
  70% {
    transform: translateX(30%);
  }
  80% {
    transform: translateX(20%);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }
}

.userForm {
  width: 80%;
  margin-top: 100px;
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.username {
  display: flex;
  justify-content: space-between;
}

.userInput {
  width: 421px;
  height: 62px;
  border: 2px solid #e93137;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  letter-spacing: 0px;
  font-size: 24px;
  color: #34373f;
}

.userInput::placeholder,
.affiliation::placeholder,
.emailInput::placeholder {
  /* font-family: Gotham; */
  /* font: normal normal normal 24px/34px Gotham; */
  /* font-family: 'Gotham'; */
  font-size: 24px;
  color: #34373f;
  opacity: 0.31;
}

.userInput::placeholder,
.affiliation::placeholder,
.emailInput::placeholder {
  /* font-family: Gotham; */
  /* font: normal normal normal 24px/34px Gotham; */
  /* font-family: 'Gotham'; */
  font-size: 24px;
  color: #34373f;
  opacity: 0.31;
}

.radioForm {
  display: flex;
  gap: 20px;
}

.label {
  text-align: left;
  font: normal normal bold 24px/34px Gotham;
  color: #34373f;
  opacity: 1;
}

.marginLabel {
  margin-left: 70px;
}

.affiliation {
  width: 803px;
  height: 62px;
  border: 2px solid #e93137;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  letter-spacing: 0px;
  font-size: 24px;
  color: #34373f;
}

.submitForm {
  display: flex;
  gap: 40px;
}

.emailInput {
  width: 637px;
  height: 62px;
  border: 2px solid #e93137;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  letter-spacing: 0px;
  font-size: 24px;
  color: #34373f;
}

.submitButton {
  width: 166px;
  height: 62px;
  border: none;
  border-radius: 7px;
  background: #e93137;
  opacity: 1;
  text-align: center;
  font: normal normal bold 18px/26px Gotham;
  color: #ffffff;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonText {
  margin: 0;
}
.submitButton:disabled,
.submitButton[disabled] {
  opacity: 50%;
}

.input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 0.07em solid #e93137;
  border-radius: 50%;
  align-items: center;
  transform: translateY(0.075em);
}

.input[type='radio']:checked {
  border: 0.07em solid black;
  background-color: #e93137;
}

@media screen and (max-height: 820px) {
  .userInput {
    width: 320px;
  }

  .affiliation {
    width: 700px;
  }
  .emailInput {
    width: 590px;
  }
}
