.container {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  animation-name: surveyYear;
  animation-duration: 0.1s;
}

@keyframes surveyYear {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(90%);
  }
  20% {
    transform: translateX(80%);
  }
  30% {
    transform: translateX(70%);
  }
  40% {
    transform: translateX(60%);
  }
  50% {
    transform: translateX(50%);
  }
  60% {
    transform: translateX(40%);
  }
  70% {
    transform: translateX(30%);
  }
  80% {
    transform: translateX(20%);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }
}

.content {
  font: normal normal normal 34px/40px Gotham;
  color: #e93237;
  width: 75%;
  margin: 8% auto;
  text-align: center;
}

.circlesContainer {
  margin-top: 120px;
}

.firstLine {
  border: 2px solid black;
  position: relative;
}

.circle {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #edf2f4;
  border: 4px solid black;
  border-radius: 50%;
  transform: translate(-30px, -50%);
}

.checkCircle {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #e93237;
  border: 4px solid black;
  border-radius: 50%;
  transform: translate(-40px, -50%);
}

.patientsQuantity {
  margin-top: 50px;
  position: relative;
}

.patients {
  position: absolute;
  width: 90px;
  font: normal normal normal 20px/34px Gotham;
  transform: translateX(-45px);
  text-align: center;
  color: #e93237;
}

.button {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 160px;
  height: 60px;
  border: none;
  border-radius: 8px;
  background-color: #e93237;
  font: normal normal normal 20px/34px Gotham;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 20px;
}
.button:disabled,
.button[disabled] {
  opacity: 50%;
}