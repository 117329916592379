.container {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  animation-name: surveyYear;
  animation-duration: 0.1s;
}

@keyframes surveyYear {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(90%);
  }
  20% {
    transform: translateX(80%);
  }
  30% {
    transform: translateX(70%);
  }
  40% {
    transform: translateX(60%);
  }
  50% {
    transform: translateX(50%);
  }
  60% {
    transform: translateX(40%);
  }
  70% {
    transform: translateX(30%);
  }
  80% {
    transform: translateX(20%);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }
}

.mapContainer:first-child {
  position: absolute;
}

.title {
  text-align: center;
  font: normal normal normal 32px/44px Gotham;
  letter-spacing: 0px;
  color: #e93137;
  opacity: 1;
  margin-bottom: 0;
}

.marker {
  z-index: 4;
  width: 30px;
  height: 30px;
  background-color: red;
}

.mark {
  width: 30px;
  height: 30px;
  background-color: red;
}

.answer {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font: normal normal normal 32px/44px Gotham;
  letter-spacing: 0px;
  color: #e93137;
}

.button {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 160px;
  height: 60px;
  border: none;
  border-radius: 8px;
  background-color: #e93237;
  font: normal normal normal 20px/34px Gotham;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 20px;
}

.button:disabled,
.button[disabled] {
  opacity: 50%;
}
