.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #edf2f4;
}

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.modalQuestion {
  color: #e93237;
  font: normal normal normal 20px/34px Gotham;
}

.modalButton {
  border: none;
  background-color: #e93237;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonText {
  color: white;
  margin: 0;
  font-size: 20px;
}
